<template>
  <el-dialog
      :title="title"
      :close-on-click-modal="false"
      :visible.sync="isOpenWatch"
      width="1100px" @open="openModal"
      @closed="closeModal">
    <div style="width: 100%;">
      <ch-form
          :render-option="option"
          :model="modalForm"
          ref="form"
          :splice="[12,9]"
          :rules="rules"
          :props="{ labelWidth: '120px', paddingRight: '10px' }"
      >
        <template v-slot:city>
          <el-cascader
              ref="city"
              :options="cascaderOption"
              v-model="modalForm.selectedOptions"
              :props="{ multiple: true }"
              collapse-tags
              size="small"
              @change="handleChange">
          </el-cascader>
        </template>
        <template v-slot:province>
          <el-cascader
              ref="province"
              :options="provinceOption"
              v-model="modalForm.province"
              :props="{ multiple: false,emitPath:false }"
              collapse-tags :show-all-levels="false"
              size="small"
              >
          </el-cascader>
        </template>
        <template v-slot:classify>
          <el-cascader
              :options="treeList"
              v-model="modalForm.classifyIds"
              :props="{ multiple: true,value: 'id', label: 'name', children: 'children',emitPath:false }"
              collapse-tags :show-all-levels="false"
              size="small"
              >
          </el-cascader>
        </template>
        <!--上传照片-->
        <template v-slot:picture>
          <ch-upload-img
              action="/hm/general/upload"
              :img-length="1"
              :removeResponse="removePicture"
              :uploadResponse="handlePicture"
              :fileList.sync="pictureFileList"
              :loading.sync="isBtnLoading"
          ></ch-upload-img>
        </template>
        <template #tags>
          <div style="display: flex;">
            <div style="padding-right: 12px"><span style="color: #F56C6C;margin-right: 4px;">*</span>标签：</div>
            <div class="tags" >
              <div class="select_warp">
                <el-select
                    v-model="selectTags"
                    multiple
                    filterable
                    allow-create
                    default-first-option
                    size="small"
                    :multiple-limit="3"
                    popper-class="select"
                    @change="tagChange"
                    placeholder="最多选择3个">
                  <el-option
                      v-for="(item,i) in tagsList"
                      :key="i"
                      :label="item.name"
                      :value="item.name">
                  </el-option>
                </el-select>
                <ch-button class="button" @click="openServiceTags" type="ok">标签库</ch-button>
              </div>
              <div class="tip">
                可以输入添加标签,标签长度不能超过6个字符
              </div>
            </div>
          </div>

        </template>
        <!--上传身份证-->
        <template v-slot:idNumber>
          <div class="idNumber">
            <ch-upload-img
                action="/hm/general/upload"
                :img-length="1"
                :removeResponse="removePositive"
                :uploadResponse="handlePositive"
                :fileList.sync="positiveFileList"
                :loading.sync="isBtnLoading"
            >
              <div slot="tip">正面</div>
            </ch-upload-img>
            <div style="width: 5px;"></div>
            <ch-upload-img
                action="/hm/general/upload"
                :img-length="1"
                :removeResponse="removeReverse"
                :uploadResponse="handleReverse"
                :fileList.sync="reverseFileList"
                :loading.sync="isBtnLoading"
            >
              <div slot="tip">反面</div>
            </ch-upload-img>
          </div>
        </template>
        <!--体检报告-->
        <template v-slot:bodyReport>
          <ch-upload-img
              action="/hm/general/upload"
              :img-length="1"
              :removeResponse="removeBodyReport"
              :uploadResponse="handleBodyReport"
              :fileList.sync="bodyReportFileList"
              :loading.sync="isBtnLoading"
          ></ch-upload-img>
        </template>
        <!--证书-->
        <template v-slot:certificate>
          <ch-upload-img
              action="/hm/general/upload"
              :img-length="1"
              :removeResponse="removeCertificate"
              :uploadResponse="handleCertificate"
              :fileList.sync="certificateFileList"
              :loading.sync="isBtnLoading"
          ></ch-upload-img>
        </template>
        <!--技能证书-->
        <template v-slot:skillBook>
          <ch-upload-img
              action="/hm/general/upload"
              :img-length="1"
              :removeResponse="removeSkillBook"
              :uploadResponse="handleSkillBook"
              :fileList.sync="skillBookFileList"
              :loading.sync="isBtnLoading"
          ></ch-upload-img>
        </template>

        <template #work>
          <div class="work" style="width: 300px">
            <div >
              <span style="color: #F56C6C;margin-right: 4px;">*</span>
              工作经历：
              <ch-button @click="addWork" style="margin-left: 10px;height: 25px" type="ok">新增</ch-button>
<!--              <ch-button @click="addWork" style="height: 25px;margin-left: 0" >删除</ch-button>-->
            </div>
            <div v-for="(item,index) in modalForm.workExperienceList" :key="index" >
              <div class="hireDate" style="width: 300px;">
                {{index+1}}、在职时间：
                <el-date-picker
                    v-model="item.hireDate"
                    type="monthrange"
                    range-separator="至" size="mini"
                    start-placeholder="开始月份"
                    end-placeholder="结束月份"
                    value-format="yyyy-MM"
                    :picker-options="pickerOptions"
                >
                </el-date-picker>
              </div>
              <el-input
                  type="textarea"
                  :rows="4"
                  placeholder="请填写工作经历"
                  v-model="item.detail">
              </el-input>
            </div>
          </div>
        </template>

        <template v-slot:profiles>
          <div class="profiles">
            <div>
              <span style="color: #F56C6C;margin-right: 4px;">*</span>
              个人介绍：
            </div>
            <el-input
                type="textarea"
                :rows="4"
                placeholder="请填写个人介绍"
                v-model="modalForm.profiles">
            </el-input>
          </div>
        </template>
      </ch-form>
    </div>
    <div slot="footer" class="flex_con">
      <ch-button type="cancel" @click="closeModal">关 闭</ch-button>
      <ch-button type="ok" :loading="isBtnLoading" @click="handleModal('form')">确 定</ch-button>
    </div>
    <service-tags ref="serviceTags" @selectTagList="changeService" ></service-tags>
  </el-dialog>
</template>

<script>
import { regionData,pcTextArr } from 'element-china-area-data'
import { phone } from "@/utils/validator";
import dayjs from "dayjs";
import {Message} from "element-ui";
import {mapGetters} from "vuex";
const serviceTags = () => import('./serviceTags.vue');
export default {
  components: {
    serviceTags
  },
  props: {
    getListFunction: {
      type: Function
    }
  },
  data(){
    let validatePass = (rule, value, callback) => {
      if (phone.test(value)) {
        // this.isPhone = true
        callback();
      } else {
        // this.isPhone = false
        callback(new Error('请输入正确手机号！'));
      }
    };
    return{
      title:'',
      type:'',
      profiles:'',
      isOpenWatch: false,
      modalForm:{
        commissionRate:'',
        appreciationProportion:'',
        workExperienceList: [
          {
            hireDate:'',
            detail:'',
          }
        ],
        selectedOptions:[],
        waistActivity:[],
        picture:'',
        idNumberPositive:'',
        idNumberReverse:'',
        idNumbers:'', // 校验是否上传了身份证正反两面
      },
      classifyOption:[],
      tagsList:[],
      selectTags:[],
      serviceList:[],
      treeList:[],
      pictureFileList:[],
      bodyReportFileList:[],
      certificateFileList:[],
      skillBookFileList:[],
      positiveFileList:[],
      reverseFileList:[],
      rules: {
        picture: [{ required: true, message: "请上传", trigger: ["blur", "change"] }],
        name: [{ required: true, message: "请输入", trigger: ["blur", "change"] }],
        phone: [{required: true, validator: validatePass, message: "请输入正确手机号", trigger: ["blur","change"] }],
        age: [{ required: true, message: "请输入", trigger: ["blur", "change"] }],
        classifyIds: [{ required: true, message: "请选择", trigger: ["blur"] }],
        commissionRate: [{  required: true, message: "请输入", trigger: ["blur", "change"] }],
        appreciationProportion: [{  required: true, message: "请输入", trigger: ["blur", "change"] }],
        selectedOptions: [{ type: 'array', required: true, message: "请选择", trigger: ["blur","change"] }],
        waistActivity: [{ required: true, message: "请选择", trigger: ["blur","change"] }],
        idNumbers: [{ required: true, message: "请上传身份证正反面", trigger: ["blur", "change"] }],
        province: [{ required: true, message: "请选择籍贯城市", trigger: ["blur", "change"] }],
      },
      nationEnumArray: [
        { label: "未知", value: 0 },
        { label: "汉族", value: 1 },
        { label: "蒙古族", value: 2 },
        { label: "回族", value: 3 },
        { label: "藏族", value: 4 },
        { label: "维吾尔族", value: 5 },
        { label: "苗族", value: 6 },
        { label: "彝族", value: 7 },
        { label: "壮族", value: 8 },
        { label: "布依族", value: 9 },
        { label: "朝鲜族", value: 10 },
        { label: "满族", value: 11 },
        { label: "侗族", value: 12 },
        { label: "瑶族", value: 13 },
        { label: "白族", value: 14 },
        { label: "土家族", value: 15 },
        { label: "哈尼族", value: 16 },
        { label: "哈萨克族", value: 17 },
        { label: "傣族", value: 18 },
        { label: "黎族", value: 19 },
        { label: "傈僳族", value: 20 },
        { label: "佤族", value: 21 },
        { label: "畲族", value: 22 },
        { label: "高山族", value: 23 },
        { label: "拉祜族", value: 24 },
        { label: "水族", value: 25 },
        { label: "东乡族", value: 26 },
        { label: "纳西族", value: 27 },
        { label: "景颇族", value: 28 },
        { label: "柯尔克孜族", value: 29 },
        { label: "土族", value: 30 },
        { label: "达斡尔族", value: 31 },
        { label: "仫佬族", value: 32 },
        { label: "羌族", value: 33 },
        { label: "布朗族", value: 34 },
        { label: "撒拉族", value: 35 },
        { label: "毛南族", value: 36 },
        { label: "仡佬族", value: 37 },
        { label: "锡伯族", value: 38 },
        { label: "阿昌族", value: 39 },
        { label: "普米族", value: 40 },
        { label: "塔吉克族", value: 41 },
        { label: "怒族", value: 42 },
        { label: "乌孜别克族", value: 43 },
        { label: "俄罗斯族", value: 44 },
        { label: "鄂温克族", value: 45 },
        { label: "德昴族", value: 46 },
        { label: "保安族", value: 47 },
        { label: "裕固族", value: 48 },
        { label: "京族", value: 49 },
        { label: "塔塔尔族", value: 50 },
        { label: "独龙族", value: 51 },
        { label: "鄂伦春族", value: 52 },
        { label: "赫哲族", value: 53 },
        { label: "门巴族", value: 54 },
        { label: "珞巴族", value: 55 },
        { label: "基诺族", value: 56 }
      ],
      educationEnumArray: [
        { label: "小学", value: 1 },
        { label: "初中", value: 2 },
        { label: "高中", value: 3 },
        { label: "大专", value: 4 },
        { label: "本科", value: 5 },
        { label: "硕士", value: 6 },
        { label: "博士", value: 7 },
        { label: "其他", value: 8 }
      ],
      isBtnLoading:false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6
        }
      },
    }
  },
  computed:{
    option: function () {
        return [
          {type: 'slot',  label: '上传照片：', slotName: 'picture', prop: 'picture'},
          {type: 'input', label: '人员姓名：', prop: 'name', placeholder: '请输入' },
          {type: 'input', label: '手机号：', prop: 'phone', placeholder: '请输入手机号' },
          {type: 'input', label: '年龄：', prop: 'age', placeholder: '请输入' },
          {type: 'select', label: '性别：', prop: 'gender', placeholder: '请选择性别',
            name: "label", value: "value", option: [
              { label: "未知", value: 0 },
              { label: "男", value: 1 },
              { label: "女", value: 2 }
            ] },
          {type: 'select', label: '学历：', prop: 'educationLevel', placeholder: '请选择学历',
            name: "label", value: "value", option: this.educationEnumArray },
          {type: 'select', label: '民族：', prop: 'ethnicity', placeholder: '请选择民族',
            name: "label", value: "label", option: this.nationEnumArray },
          {type: 'input', label: '身高：', prop: 'height', placeholder: '请输入',sign:'cm' },
          {type: 'slot',  label: '服务城市：', slotName: 'city' ,prop: 'selectedOptions',},
          {type: 'input', label: '街道地址：', prop: 'address', placeholder: '请输入' },
          {type: 'slot', label: '服务种类：', prop: 'classifyIds', slotName:'classify' },
          {type: 'input', label: '工作年限：', prop: 'workExperience', placeholder: '请输入',sign:'年' },
          {type: 'slot',  label: '籍贯城市：', slotName: 'province' ,prop: 'province',},
          {type: 'slot', label: '', slotName:'tags', prop: 'tags', labelWidth:'55px' },
          {type: 'number', label: '服务分成比例：', prop: 'commissionRate', placeholder: '请输入',sign: '%',precision:2 },
          {type: 'number', label: '增值分成比例：', prop: 'appreciationProportion', placeholder: '请输入',sign: '%',precision:2 },
          {type: 'select', label: '腰部分类：', prop: 'waistActivity', placeholder: '请选择服务类型',
            name: "name", value: "id", option: [
              {name:'营销活动1',id:1},
              {name:'营销活动2',id:2},
              {name:'营销活动3',id:3},
            ] },
          {type: 'slot',  label: '身份证：', slotName: 'idNumber', prop: 'idNumbers',},
          {type: 'slot',  label: '体检报告：', slotName: 'bodyReport', prop: 'bodyReport'},
          {type: 'slot',  label: '证件证书：', slotName: 'certificate', prop: 'certificate'},
          {type: 'slot',  label: '技能证书：', slotName: 'skillBook', prop: 'skillBook'},
          {type: 'slot',  label: '', slotName: 'work', prop: 'workExperienceList',labelWidth:'40px'},
          {type: 'slot',  label: '', slotName: 'profiles', props: 'profiles',labelWidth:'40px'},
        ]
    },
    cascaderOption: function () {
      const arr = this.userInfo.areaIdsSet || []
      const newRegionData = regionData.map(item=>{
        item.disabled = !arr.includes(item.value)
        item.children = item.children?.map(child=>{
          child.disabled = !arr.includes(child.value)
          child.children = child.children?.map(childItem=>{
            childItem.disabled = !arr.includes(childItem.value)
            return {...childItem}
          })
          return {...child}
        })
        return {...item}
      })
      return newRegionData.sort((a,b)=>{
        if(a.disabled && b.disabled){
          return 0
        }
        return a.disabled ? 1 : -1;
      })
    },
    provinceOption: function (){
      return  pcTextArr.map(item=>{
        if(item.label.includes('市')){
          item.children = null
        }
        return item
      })
    },
    ...mapGetters(['userInfo'])
  },
  mounted() {
    this.getTreeList()
  },
  methods:{
    // 打开前处理
    openModal() {
      this.$nextTick(() => {
        this.$refs?.form?.clearValidate();
      })
    },
    // 服务种类 树
    async getTreeList(){
      this.$curl.get('/hm/classify/tree').then(res=>{
        this.treeList = res.data
      })
    },
    tagChange(event){
      const len = event.length
      if(event[len-1].length>6){
        this.selectTags.pop()
        this.$message.warning('长度超过6个字符，请重新输入')
      }
    },
    openServiceTags(){
      this.$refs?.serviceTags.openTagsList(this.serviceList)
    },
    changeService(val){
      this.selectTags = []
      this.serviceList = val
      this.tagsList =  Object.values(val.reduce((acc, item) => {
        // 如果 id 不在 accumulator 对象中，添加它
        if (!acc[item.id]) {
          acc[item.id] = item;
        }
        return acc;
      }, {}));
    },
    // 添加工作经历
    addWork(){
      this.modalForm.workExperienceList.push({
        hireDate: null,
        detail: ''
      })
    },
    openAdd(){
      this.type = 'add'
      this.isOpenWatch= true
      this.title = '新增服务人员'
    },
    // 编辑
    openEdit(row){
      this.type = 'edit'
      this.isOpenWatch= true
      this.title = '编辑服务人员'
      this.modalForm = JSON.parse(JSON.stringify(row));
      this.pictureFileList = row.picture ? [{url: row .picture}]:[]
      this.bodyReportFileList = row.bodyReport ? [{url: row.bodyReport}]:[]
      this.certificateFileList = row.certificate ? [{url: row.certificate}]:[]
      this.skillBookFileList = row.skillBook ? [{url: row.skillBook}]:[]
      this.positiveFileList = row.idNumberPositive ? [{url: row.idNumberPositive}]:[]
      this.reverseFileList = row.idNumberPositive ? [{url: row.idNumberReverse}]:[]
      this.modalForm.idNumberPositive = row.idNumberPositive
      this.modalForm.idNumberReverse = row.idNumberReverse
      this.modalForm.idNumbers = 'success'
      delete this.modalForm.jobNumber
      if(row.areaIdsJson){
        this.modalForm.selectedOptions = JSON.parse(row.areaIdsJson)
      }
      if(row?.tags){
        this.tagsList = row?.tags.split(',').map(item=>{
          return {
            name: item
          }
        })
        this.selectTags = this.tagsList.map(item=> item.name)
      }
      if(row.workExperienceList){
        this.modalForm.workExperienceList = this.modalForm.workExperienceList?.map(item => {
          return {
            ...item,
            hireDate: item.hireDate?.split('一')
          }
        })
      }else{
        this.modalForm.workExperienceList = [
          {
            hireDate:'',
            detail:'',
          }
        ]
      }
    },
    // 上传照片
    handlePicture(res) {
      this.modalForm.picture = res.data.url
      this.$refs.form.clearValidate('picture')
    },
    // 删除照片
    removePicture() {
      this.modalForm.picture = ''
    },
    // 上传身份证正面
    handlePositive(res) {
      this.modalForm.idNumberPositive = res.data.url
      if(this.modalForm.idNumberPositive&&this.modalForm.idNumberReverse){
        this.modalForm.idNumbers = 'success'
        this.$refs.form.clearValidate('idNumbers')
      }else{
        this.modalForm.idNumbers = ''
      }
    },
    // 删除身份证正面
    removePositive() {
      this.modalForm.idNumberReverse = ''
      this.modalForm.idNumbers = ''
    },
    // 上传身份证反面
    handleReverse(res) {
      this.modalForm.idNumberReverse = res.data.url
      if(this.modalForm.idNumberPositive&&this.modalForm.idNumberReverse){
        this.modalForm.idNumbers = 'success'
        this.$refs.form.clearValidate('idNumbers')
      }else{
        this.modalForm.idNumbers = ''
      }
    },
    // 删除身份证反面
    removeReverse() {
      this.modalForm.idNumberReverse = ''
      this.modalForm.idNumbers = ''
    },
    // 上传体检报告
    handleBodyReport(res){
      this.modalForm.bodyReport = res.data.url
    },
    // 删除体检报告
    removeBodyReport(){
      this.modalForm.bodyReport = ''
    },
    // 上传证书
    handleCertificate(res){
      this.modalForm.certificate = res.data.url
    },
    // 删除证书
    removeCertificate(){
      this.modalForm.certificate = ''
    },

    // 上传技能证书
    handleSkillBook(res) {
      this.modalForm.skillBook = res.data.url
    },
    // 删除技能证书
    removeSkillBook() {
      this.modalForm.skillBook = ''
    },
    // 选择城市
    handleChange () {
      let checkedNodeList = this.$refs.city.getCheckedNodes();
      checkedNodeList = checkedNodeList.filter(item => !(item.parent&&item.parent.checked))

      this.modalForm.areaList = checkedNodeList.map(item=>{
        if(item.level===1 && item.children[0].label!=='市辖区'){
          let arr = []
          item.children.forEach(v=>{
            if(v.checked) {
              v.areaObj = {
                provinceName: item.label,
                cityName: v.label,
                districtName: v.label,
              }
              arr.push(v.areaObj)
            }
          })
          return arr;
        }else if(item.level===1&&item.children[0].label==='市辖区'){
          return {
            provinceName: item.label,
            cityName: item.label,
            districtName: item.label,
          }
        }
        if(item.level===2){ // 市 全选市，区的值给市的值
          return {
            provinceName:item.pathLabels[0],
            cityName:item.pathLabels[1],
            districtName:item.pathLabels[1],
          }
        }
        if(item.level===3&&item.parent.label!=='市辖区'){
          return{
            provinceName:item.pathLabels[0],
            cityName:item.pathLabels[1],
            districtName:item.pathLabels[2],
          }
        } else if(item.level===3&&item.parent.label==='市辖区'){
          return {
            provinceName:item.pathLabels[0],
            cityName:item.pathLabels[0],
            districtName:item.pathLabels[2],
          }
        }
      })
      this.modalForm.areaList =  this.modalForm.areaList.flat()
    },
    // 确定
    handleModal(formRef){
      this.$refs[formRef].validate((valid) => {
        if (valid) {
          if(this.selectTags.length===0){
            Message({type: "warning", message: "请选择标签!",})
            return
          }
          if(!(this.modalForm.idNumberPositive&&this.modalForm.idNumberReverse)){
            Message({type: "warning", message: "请上传正反面身份证!",})
            return
          }
          if(!(this.modalForm.workExperienceList[0].detail && this.modalForm.workExperienceList[0].hireDate)){
            Message({type: "warning", message: "请填写完整工作经历!",})
            return
          }
          if(!this.modalForm.profiles){
            Message({type: "warning", message: "请填写个人介绍!",})
            return
          }

          this.modalForm.tags = this.selectTags?.map(item=> item).join(',')
          this.modalForm.workExperienceList = this.modalForm.workExperienceList.map(work=>{
            return {
              ...work,
              hireDate:work.hireDate?.map(item =>dayjs(item).format('YYYY-MM')).join('一')
            }
          })
          this.modalForm.areaIdsJson = JSON.stringify(this.modalForm.selectedOptions)
          this.isBtnLoading = true
          if(this.type === 'add'){
            this.$curl.post('/hm/serviceStaff/add',this.modalForm) .then(() => {
              this.isBtnLoading = false
              this.$message.success("新增成功！");
              this.closeModal()
              this.getListFunction?.()
            }).catch(() => {this.isBtnLoading = false})
          }else{
            this.$curl.post('/hm/serviceStaff/edit',this.modalForm) .then(() => {
              this.isBtnLoading = false
              this.$message.success("编辑成功！");
              this.closeModal()
              this.getListFunction?.()
            }).catch(() => {this.isBtnLoading = false})
          }
        }
      })
    },
    closeModal(){
      this.isOpenWatch = false
      this.modalForm = {
        workExperienceList: [
          {
            hireDate:'',
            detail:'',
          }
        ],
      }
      this.pictureFileList = []
      this.bodyReportFileList = []
      this.certificateFileList = []
      this.skillBookFileList = []
      this.positiveFileList = []
      this.reverseFileList = []
      this.tagsList = []
      this.serviceList = []
      this.selectTags = []
    },
  }
}
</script>

<style lang="scss" scoped>
.tags{
  width: 100%;
  flex-direction: column;
  .select_warp{
    display: flex;
    .button{
      height: 30px;
      line-height: 0;
    }

  }
  .tip{
    color: #999999;
    font-size: 12px;
  }
}
.idNumber{
  display: flex;
  text-align: center;
  flex: 1;
}
.work{
  .hireDate{
    width: 300px;
    display: flex;
    align-items: center;
    ::v-deep .el-range-separator{
      width: fit-content;
    }
  }
}
.select{
  width: 100%;
  .el-select-dropdown__item{
    padding: 0 15px;
  }
}
</style>